import { Component, OnInit, Input, OnDestroy, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
/*import { Subscription } from 'rxjs';*/
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/*import { HttpClient, HttpRequest } from '@angular/common/http';**/
import { HttpEventType, HttpResponse } from '@angular/common/http';

/* Modal Window */
import { ModalComponent } from './modal/modal.component';

/* Translate */
import { TranslateService } from '@ngx-translate/core';

/* Components */
import { DocumentEditComponent } from './document-center/document-edit/document-edit.component';

/* Services */
import { AiService } from './services/ai.service';
import { UploadService } from './services/upload.service';
import { MembersettingsService } from './services/membersettings.service';
import { DockService } from './services/dock.service';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { NavigationService } from './services/navigation.service';
import { EmailService } from './services/mail.service';
import { InitService } from './services/init.service';
import { LatestChangesService } from './services/latest-changes.service';
import { SpeechToTextService } from './services/speech-to-text.service';

/* speech-to-text services and components */
//import { Scavenger } from '@wishtack/rx-scavenger';
/*import { NgZone } from '@angular/core';*/

import * as pdfjsLib from 'pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.min.js';

interface JWT_user {
	logged_in: boolean;
	user_name: string;
	name: string;
	user_id: string;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {

	public admin_rights = false;
	public aiRequestText = "";
	public aiLog = [];
	public showLog: boolean = false;
	private hideTimeout: any;
	public aiAgentActivate = false;
	public aiAgentActivateLog = false;
	public unprocessedEmails = 99;
	public unreadLatestCount = 0;
	public processingEmails = false;
	public processingPercent = 100;
	private intervalId: any;
	public new = true;
	public auth_info$: Observable<JWT_user>;
	public isLogged = false;
	public topNavigation = [];
	public topNavigationShortcuts = {};
	public quickNavigationShortcuts = {};
	public localFunction = [];
	public topSearch = "";
	public globalSearch = false;
	public memberSettingsGlobal = [];
	public memberAllowance = [];
	private lastScrollTop = 0;
	public direction = "";
	public voiceRecognition = false;
	public localSearchOption = false;
	public component = 'app';
	public lastFunctionCallAt = Date.now();
	public dockGlobal = [];

	/*******************************************************/

	@ViewChildren('trigger') triggers!: QueryList<ElementRef>;
	@ViewChildren('tabs') tabs!: QueryList<ElementRef>;

	/*******************************************************/

	constructor(
		private auth_svc: AuthService,
		private router: Router,
		public translate: TranslateService,
		private data: DataService,
		private NavigationService: NavigationService,
		private DockService: DockService,
		private MembersettingsService: MembersettingsService,
		private SpeechToTextService: SpeechToTextService,
		private UploadService: UploadService,
		private LatestChangesService: LatestChangesService,
		//private _ngZone: NgZone,
		public dialog: MatDialog,
		//private http: HttpClient,
		private EmailService: EmailService,
		private AiService: AiService,
		private InitService: InitService
	) {
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				this.auth_info$ = this.auth_svc.get_user_details();
				this.auth_info$.subscribe(data => this.isLogged = data.logged_in);
			}
		});
		translate.addLangs(['en', 'si']);		
	}

	/*******************************************************/

	onScroll(event: any): void {
		const scrollTop = event.target.scrollTop;
		if (scrollTop > this.lastScrollTop) {
			this.direction = 'scrollDown';
		} else {
			this.direction = 'scrollUp';
		}
		this.lastScrollTop = scrollTop;
	}

	/*******************************************************/

	aiRequest(value = this.aiRequestText) {
		//console.log('*** AI Reponse');
		this.AiService.onExecute(value);
		this.aiRequestText = null;
	}

	/*******************************************************/

	openGlobalSearch() {
		this.globalSearch = true;
	}

	/*******************************************************/

	pinQuickNavigationItem(entity, action = 'add') {
		let currentState = this.quickNavigationShortcuts[entity] == false || this.quickNavigationShortcuts[entity] == undefined ? true : false;
		this.quickNavigationShortcuts[entity] = currentState;
		this.MembersettingsService.setMemberSettings('quickIcons', JSON.stringify(this.quickNavigationShortcuts));
	}

	pinTopNavigationItem(entity, action = 'add') {
		let currentState = this.topNavigationShortcuts[entity] == false || this.topNavigationShortcuts[entity] == undefined ? true : false;
		this.topNavigationShortcuts[entity] = currentState;
		this.MembersettingsService.setMemberSettings('shortCutsIcons', JSON.stringify(this.topNavigationShortcuts));
	}

	toggleSideBar() {
		this.memberSettingsGlobal['showSidebar'] = this.memberSettingsGlobal['showSidebar'] > 1 ? 0 : parseInt(this.memberSettingsGlobal['showSidebar']) + 1;
		if (isNaN(this.memberSettingsGlobal['showSidebar'])) this.memberSettingsGlobal['showSidebar'] = 0;
		this.NavigationService.updateShowSidebar(this.memberSettingsGlobal['showSidebar']);
		this.MembersettingsService.setMemberSettings('showSidebar', this.memberSettingsGlobal['showSidebar']);
	}

	toggleOpenCloseMain(id, item = null) {
		let element = document.getElementById(id);
		let isOpened = element.classList.contains("activeMainNavigation");

		let elements = document.querySelectorAll('.activeMainNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeMainNavigation'); }

		if (!isOpened) element.classList.add('activeMainNavigation');

		if (item != null) {
			//console.log(item);
			//if (item['refreshable'] == true) { this.NavigationService.updateSubmenuOnRequest(item.component); }
		}
	}

	toggleOpenCloseSub(id, item = null) {
		//subMenus
		let element = document.getElementById(id);
		let isFunction = element.classList.contains("function");
		let isOpened = element.classList.contains("activeSubNavigation");

		let elements = document.querySelectorAll('.activeSubNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeSubNavigation'); }

		if (!isOpened) element.classList.add('activeSubNavigation');
		if (isFunction) {
			elements = document.querySelectorAll('.activeMainNavigation');
			for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeMainNavigation'); }
			elements = document.querySelectorAll('.activeSubNavigation');
			for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeSubNavigation'); }
		}

		let subHeightHelper = document.querySelector("#appTopNavigation .topNavigationButton .activeSubNavigation .subSubMenuWrapper") as HTMLElement;
		if (subHeightHelper != null) {
			let subHeight = subHeightHelper.offsetHeight;
			let subTop = subHeightHelper.getBoundingClientRect().top;

			let appHeightHelper = document.querySelector("#appContent") as HTMLElement;
			let appHeight = appHeightHelper.offsetHeight;
			let appTop = appHeightHelper.getBoundingClientRect().top;

			let top = subTop;
			if (subHeight >= appHeight) {
				top = subTop - appTop - 29;
				subHeightHelper.style.top = "-" + top + "px";
				return true;
			}

			if (subHeight + subTop > appHeight + appTop) {
				top = (subHeight + subTop) - (appHeight + appTop) - 32;
				subHeightHelper.style.top = "-" + top + "px";
				return true;
			}
		}

		if (item != null) {
			//console.log(item);
			if (item['refreshable'] == true) { this.NavigationService.updateSubmenuOnRequest(item.component); }
		}

		return true;
	}

	closeCloseAll() {
		let elements = document.querySelectorAll('.activeMainNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeMainNavigation'); }

		elements = document.querySelectorAll('.activeSubNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeSubNavigation'); }		
	}

	/*******************************************************/

	icon(value) {
		if (value == null) return ['fad', 'circle-xmark'];
		let output = value.split(",");
		if (output.length != 2) {
			output = ['fad', 'circle-xmark'];
		} 
		return output;
	}

	isIconSelected(entity) {
		let selected = false;
		for (let key in this.topNavigationShortcuts) {
			if (key == entity && this.topNavigationShortcuts[key]) selected = true;
		}
		return selected;
	}

	isIconQuickSelected(entity) {
		let selected = false;
		for (let key in this.quickNavigationShortcuts) {
			if (key == entity && this.quickNavigationShortcuts[key]) selected = true;
		}
		return selected;
	}

	/*******************************************************/
 
	navigate(type, action, component, data) {
		this.NavigationService.navigate(type, action, component, data);
	}

	onSearch(event: any) {
		this.NavigationService.localSearchFunction(event.target.value);
	}

	removeFromDock(type, entity) {
		this.DockService.removeFromDock(type, entity);
	}

	/*******************************************************/
	
	ngOnInit() {
		console.log('App Init. (start)');
		/* General Init */
		this.InitService.updateAll();	

		/* memberAllowance */
		this.MembersettingsService.getMemberAllowance();
		this.MembersettingsService.memberAllowance.subscribe(message => {
			this.memberAllowance = message;
			this.admin_rights = this.memberAllowance['admin_rights'];			
		});

		/* Navigation Service */
		this.NavigationService.topNavigation.subscribe(message => {
			this.topNavigation = message;
			for (let item of this.topNavigation) {
				this.localSearchOption = item['entity'] == "function:localSearch" ? true : false;
			}
			console.log('Navigation service Init. (topNavigation)');
			//console.log(this.topNavigation);
		});
		this.NavigationService.showSidebar.subscribe(message => this.memberSettingsGlobal['showSidebar'] = message);

		/* Handle local function called form navigation service */
		this.NavigationService.localFunction.subscribe(callLocalFunction => {			
			if (callLocalFunction[0] == this.component && callLocalFunction[1] == "localFunction" && this.lastFunctionCallAt < Date.now()) {
				this[callLocalFunction[2]](callLocalFunction[3]);
				this.lastFunctionCallAt = Date.now() + 500; //prevent calling function too fast by default 500mS between call
			}
			console.log('Member service Init. (localFunction)');
		});

		/* authorization */
		this.auth_info$ = this.auth_svc.get_user_details();
		this.auth_info$.subscribe(data => {
			this.isLogged = data.logged_in			
		});

		/* MemberSettings Service */
		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {
			this.memberSettingsGlobal = message;
			this.memberSettingsGlobal['showSidebar'] = this.memberSettingsGlobal['showSidebar'] != undefined ? parseInt(this.memberSettingsGlobal['showSidebar']) : 0;
			if (this.memberSettingsGlobal['shortCutsIcons'] != undefined) this.topNavigationShortcuts = JSON.parse(this.memberSettingsGlobal['shortCutsIcons']);
			if (this.memberSettingsGlobal['quickIcons'] != undefined) this.quickNavigationShortcuts = JSON.parse(this.memberSettingsGlobal['quickIcons']);			
			if (this.memberSettingsGlobal['dock'] != undefined) this.DockService.refreshDock(JSON.parse(this.memberSettingsGlobal['dock']));			
			if (this.memberSettingsGlobal['aiAgent']!=null) { this.aiAgentActivate = true; this.aiAgentActivateLog = true; } else { this.aiAgentActivate = false; this.aiAgentActivateLog = false; }

			/* Set Language */
			console.log(this.memberSettingsGlobal['lang']);
			if (this.memberSettingsGlobal['lang'] != undefined) {
				this.translate.use(this.memberSettingsGlobal['lang']);				
			}
			setTimeout(() => {this.translate.use(this.memberSettingsGlobal['lang']); }, 500);});

		/* Dock Service */
		this.DockService.dockGlobal.subscribe(message => {
			this.dockGlobal = message;			
		});

		/* AI Agent */
		this.AiService.aiLog.subscribe(message => {
			this.aiLog = message;			
			this.showLog = true;
			if (this.hideTimeout) {
				clearTimeout(this.hideTimeout);
			}
			this.hideTimeout = setTimeout(() => {
				this.showLog = false;
			}, 3000);
			this.initBottomTabs();	
		});

		/* Mail Service */
		this.EmailService.update('scheduled');
		this.EmailService.countEmailQueueByStatusVar.subscribe(message => {
			if (message == undefined) {
				this.unprocessedEmails = 0;
			} else {
				this.unprocessedEmails = message[0]['total'];
			}			
		});
	}

	/************************************************************/

	ngAfterViewInit() {

		this.triggers.changes.subscribe(() => { this.initializeTriggers(); });
		this.initializeTriggers();

		this.tabs.changes.subscribe(() => { this.initBottomTabs(); });		
		this.initBottomTabs();

		/*
		const mouseTarget = document.querySelector("#appTopNavigation");
		mouseTarget.addEventListener("mouseleave", (e) => {
			this.closeCloseAll();
		});

		document.body.addEventListener('mousemove', function (event) {
			var x = event.clientX, y = event.clientY;
			var w = window.innerWidth;
			var h = window.innerHeight;
			if (document.getElementById('quickFunctions')) {
				if (x + 52 > w && y + 52 > h) {
					document.getElementById('quickFunctions').classList.add("active");
				} else {
					document.getElementById('quickFunctions').classList.remove("active");
				}
			}
		});
		*/

		/**************************************************/
		/* LatestChangesService */
		//this.LatestChangesService.update();
		this.LatestChangesService.lastActivities.subscribe(message => {
			if (message == undefined) {
				this.unreadLatestCount = 0;
			} else {
				this.unreadLatestCount = message.length;
			}
			
		});

		/*************************************/
		/* prevent move windows to far */		
		/*
		document.addEventListener('mousedown', (event) => {
			const target = event.target as HTMLElement;
		  
			if (!(target instanceof HTMLElement)) return;
		  
			const modal = target.closest('.mat-mdc-dialog-panel') as HTMLElement | null;
		  
			if (!modal) return;
		  
			let startX = event.clientX;
			let startY = event.clientY;
		  
			const rect = modal.getBoundingClientRect();
		  
			let initialLeft = rect.left;
			let initialTop = rect.top;
		  
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;
		  
			const onMouseMove = (moveEvent: MouseEvent) => {
			  const deltaX = moveEvent.clientX - startX;
			  const deltaY = moveEvent.clientY - startY;
		  
			  modal.style.position = 'absolute';
			  modal.style.left = `${initialLeft + deltaX}px`;
			  modal.style.top = `${initialTop + deltaY}px`;
			  modal.style.transform = 'none';
			};
		  
			const onMouseUp = () => {
			  const rectAfterMove = modal.getBoundingClientRect();
		  
			  let newLeft = parseFloat(modal.style.left);
			  let newTop = parseFloat(modal.style.top);
		  
			  // Preveri meje (75%)
			  if (rectAfterMove.left < -0.90 * rectAfterMove.width) {
				newLeft = 0;
			  } else if (rectAfterMove.right > viewportWidth + 0.90 * rectAfterMove.width) {
				newLeft = viewportWidth - rectAfterMove.width;
			  }
		  
			  if (rectAfterMove.top < -0.90 * rectAfterMove.height) {
				newTop = 0;
			  } else if (rectAfterMove.bottom > viewportHeight + 0.90 * rectAfterMove.height) {
				newTop = viewportHeight - rectAfterMove.height;
			  }
		  
			  modal.style.transition = 'left 400ms ease, top 400ms ease';
			  modal.style.left = `${newLeft}px`;
			  modal.style.top = `${newTop}px`;
		  
			  setTimeout(() => {
				modal.style.transition = '';
			  }, 400);
		  
			  document.removeEventListener('mousemove', onMouseMove);
			  document.removeEventListener('mouseup', onMouseUp);
			};
		  
			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		});		  
		*/
		
		document.addEventListener("mouseup", function () {
			// Poiščemo vsa modalna okna, ki so trenutno odprta
			const modals = document.querySelectorAll(".mat-mdc-dialog-panel") as NodeListOf<HTMLElement>;
			if (!modals.length) return;
		
			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;
		
			modals.forEach((modal) => {
				const rect = modal.getBoundingClientRect();
				const modalWidth = rect.width;
				const modalHeight = rect.height;
		
				// 🔹 **Preverimo, ali je modal izven dovoljenih meja (več kot 90% izven vidnega polja)**
				let isOutOfBounds =
					rect.right < modalWidth * 0.1 ||  // Preveč levo
					rect.left > windowWidth - modalWidth * 0.9 || // Preveč desno
					rect.bottom < modalHeight * 0.1 || // Preveč gor
					rect.top > windowHeight - modalHeight * 0.9; // Preveč dol
		
				// 🔹 **Če je modal izven dovoljenih meja, ga postavimo nazaj v center**
				if (isOutOfBounds) {
					modal.style.position = "absolute";
					modal.style.left = `${(windowWidth - modalWidth) / 2}px`;
					modal.style.top = `${(windowHeight - modalHeight) / 2}px`;
				}
			});
		});
		
		

		




		
		
		
		
		

		
		







		/**************************************************/
		/* START: Uploader */
		var lastTarget = null;
		window.addEventListener("dragenter", function (e) {
			lastTarget = e.target;
			localStorage.setItem("fileToUpload", '');
			const element = document.getElementsByClassName("file_container")[0] as HTMLElement;
			element.style.visibility = "";
			element.style.opacity = "1";
		});

		window.addEventListener("dragleave", function (e) {
			localStorage.setItem("fileToUpload", 'finder');
			if (e.target === lastTarget || e.target === document) {
				(document.getElementsByClassName("file_container")[0] as HTMLElement).style.visibility = "hidden";
			}
		});
				
		/* END: Uploader */
		/**************************************************/

		/**************************************************/
		/* interval */		
		this.intervalId = setInterval(() => { this.executeIntervalFunctions(); }, 60000);
		
		/**************************************************/
		/* Action JS */		
		this.initBottomTabs();
		this.intervalId = setInterval(() => { this.initBottomTabs(); }, 500);
		
		/**************************************************/


		/* remove startup spinner */
		document.getElementById("infitiny-text").remove();
		document.getElementById("startUpSpinner").remove();

		console.log('App Init. (finish)');
	}

	/*******************************************************/
	
	initBottomTabs() {
		const quickTabs = document.querySelectorAll<HTMLDivElement>(".quickTab");
		const quickTabBodies = document.querySelectorAll<HTMLDivElement>(".quickTabBody");

		let accumulatedWidth = 0;

		quickTabs.forEach((quickTab, index) => {
			const measuredHeight = quickTab.offsetHeight * -1 + 36;
			quickTab.style.bottom = `${measuredHeight}px`;

			const quickTabBody = quickTabBodies[index];

			if (quickTabBody) {
				const bodyWidth = quickTabBody.offsetWidth;

				quickTab.style.width = `${bodyWidth}px`;
				quickTab.style.left = `${accumulatedWidth}px`;
				accumulatedWidth += bodyWidth + 12;
				
			}
		
			if (index === quickTabs.length - 1 && quickTabBody) {
				const bodyWidth = quickTabBody.offsetWidth;
				quickTab.style.right = "0px";
				quickTab.style.left = "auto";
				quickTab.style.width = `${bodyWidth}px`;
				accumulatedWidth += bodyWidth + 12;
			}	
		});

		window.scrollTo(0, 0);
	}

	/************************************************************/

	initializeTriggers() {		
		document.querySelectorAll('.trigger').forEach((element) => {	
			const trigger = element as HTMLElement; // Pravilno type casting
			trigger.addEventListener('click', function () {
				const panelId = trigger.getAttribute('rel');
				console.log(panelId);
				const panel = document.getElementById(panelId || '');

				if (!panel) { console.error(`Panel z ID "${panelId}" ne obstaja.`); return; }

				if ((panel as HTMLElement).style.display === 'block') {
					hideAllPanels();
				} else {
					showPanel(trigger);
				}
			});
		});

		document.addEventListener('click', function (event) {
			const target = event.target as HTMLElement;
			const triggers = Array.from(document.querySelectorAll<HTMLElement>('.trigger'));
			const panels = Array.from(document.querySelectorAll<HTMLElement>('.panel'));

			if (![...triggers, ...panels].some(el => el.contains(target))) {
				hideAllPanels();
			}
		});

		function hideAllPanels() {
			document.querySelectorAll<HTMLElement>('.panel').forEach((panel: HTMLElement) => {
				panel.style.display = 'none';
			});
		}

		function showPanel(trigger: HTMLElement) {
			const panelId = trigger.getAttribute('rel');
			const panel = document.getElementById(panelId || '');

			if (!panel) { console.error(`Panel z ID "${panelId}" ne obstaja.`); return; }

			document.querySelectorAll<HTMLElement>('.panel').forEach((p: HTMLElement) => {
				p.style.display = 'none';
			});

			const triggerRect = trigger.getBoundingClientRect();
			panel.style.visibility = 'hidden';
			panel.style.display = 'block';
			const panelWidth = panel.offsetWidth;
			panel.style.top = `${triggerRect.bottom + 16}px`;
			panel.style.left = `${triggerRect.right - panelWidth + 8}px`;
			panel.style.visibility = 'visible';
		}
	}

	ngOnDestroy(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	closeMenu() {
		let elements = document.querySelectorAll('.activeSubNavigation');
		for (var i = 0; i < elements.length; i++) { elements[i].classList.remove('activeSubNavigation'); }
	}

	/***************************************************/
	/***************************************************/
	/***************************************************/

	sendScheduledEmails() {
		this.processingEmails = true;
		this.data.getEmailQueueByStatus({ 'status': 'scheduled' }).subscribe(
			(res: Response) => {
				const emails = res['rows'];
				const step = 100 / emails.length;
				this.processingPercent = 0;
				for (let i = 0; i < emails.length; i++) {
					setTimeout(() => {
						//console.log(emails[i]);
						emails[i]['to'] = emails[i]['recipient_email'];
						this.data.sendEmail(emails[i]).subscribe(
							(res: Response) => {
								this.EmailService.update('scheduled');
							}
						)						

						this.processingPercent +=  step;
						if (i == emails.length - 1) {
							setTimeout(() => { this.processingEmails = false; }, 500);
						}
					}, i * 1500);
				}

				/*
				const keys = Object.keys(this.myObject); // Dobimo vse ključe objekta

				
				keys.forEach((key, index) => {
				setTimeout(() => {
					// Uporabimo podatke za vsak ključ
					console.log(`Ključ: ${key}, Vrednost: ${this.myObject[key]}`);

					// Če je zadnji element, ponovno začni zanko
					if (index === keys.length - 1) {
					this.loopThroughObject();
					}
				}, index * 5000); // Timeout pomnožen z indeksom (5 sekund na ključ)
				});



				this.intervalId = setInterval(() => {
					this.useData(this.myObject);
				  }, 5000);
				*/
				/*
				function sendEmail($parameters) {		
					$to = $parameters["to"] ?? null;
					$subject = $parameters["subject"] ?? null;
					$body = $parameters["body"] ?? null;
					$attachments = $parameters["attachments"] ?? [];
				*/

			}
		);
	}

	/*
	sendDemoEmail() {
		this.data.sendDemoEmail(null).subscribe(
			(res: Response) => {
				console.log(res);
			}		
		);	
	}
	*/

	/***************************************************/
	/***************************************************/
	/***************************************************/

	executeIntervalFunctions() {
		this.LatestChangesService.update();
	}

	/***************************************************/
	/***************************************************/
	/***************************************************/
	/***************************************************/
	/***************************************************/
	/* Voice Recognition */
	voiceRecognitionStop() {
		this.SpeechToTextService.voiceRecognitionStop();
		this.voiceRecognition = false;
	}

	voiceRecognitionStart() {
		this.SpeechToTextService.voiceRecognitionStart();
	}

	/***************************************************/
	/***************************************************/
	/***************************************************/
	/***************************************************/
	/***************************************************/
	/* START: uploader add on */
	public files: any[] = [];
	public selectedFiles: FileList;
	public currentFile: File;
	public progress = 0;
	public fileInfos: Observable<any>;
	private document_details;
	public fileChooser = false;

	private _openDialog() { if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) return; }

	openFileUploadDialog() {
		/* file chooser */
		localStorage.setItem("fileToUpload", '');
		const element = document.getElementsByClassName("file_container")[0] as HTMLElement;
		element.style.visibility = "";
		element.style.opacity = "1";
		this.fileChooser = true;
	}

	prepareFilesList(files: Array<any>, directUpload = false) {
		for (const item of files) {
			item['directUpload'] = directUpload; //avoid file dialog and categorizing
			item.progress = 0;
			this.files.push(item);
		}
		this.uploadFiles(0);
		this.fileChooser = false;
	}

	onFileDropped($event) {
		this.closeDropField();
		this._openDialog();

		let call = null;
		let customer = null;
		let customer_id = null;
		let entity = null;
		let title = null;
		let folder = null;
		let category = '?';
		let category_id = '?';
		let restrictedUpload = false;

		/**/

		////console.log('>>>>', document.getElementsByClassName('dialog-comment').length);

		/* document center */
		if (this.router.url == '/document-center') {
			if (this.memberSettingsGlobal['documentmanager'] != undefined) {
				/* priority refresh data*/
				this.memberSettingsGlobal['documentmanager'] = this.MembersettingsService.memberSettingsGlobal['documentmanager'];

				if ((this.memberSettingsGlobal['documentmanager']).substring(0, 7) == "folder|") {
					folder = (this.memberSettingsGlobal['documentmanager']).replace("folder|", "");
					category = null;
					category_id = null;
				} else {
					restrictedUpload = true;
				}
			}
		}

		/* activity */
		if (document.getElementsByClassName('dialog-activity').length > 0 && document.getElementsByClassName('dialog-comment').length == 0) {
			localStorage.setItem("fileToUpload", 'activity');
			customer = (<HTMLInputElement>document.getElementById('activityCustomer')).value;
			customer_id = (<HTMLInputElement>document.getElementById('activityCustomerId')).value;
			category = '?';
			category_id = '?';
			entity = (<HTMLInputElement>document.getElementById('activityEntity')).value;
			title = (<HTMLInputElement>document.getElementById('activityTitle')).value;
		};

		/* comment */
		if (document.getElementsByClassName('dialog-comment').length > 0) {
			localStorage.setItem("fileToUpload", 'comment');
			category_id = "-1";
			entity = 9999;
		}

		if (restrictedUpload) {
			var content = this.translate.instant('You do not have right to upload files in this folder category');
			var modalDialog = this.dialog.open(ModalComponent, {
				data: { content: content, yes: this.translate.instant('OK'), no: null, cancel: null }
			});
		} else {
			if (category_id != '-1') {
				var editDialog = this.dialog.open(DocumentEditComponent, {
					'width': '800px',
					data: {
						files: $event,
						prefill: {
							call: call,
							customer: customer,
							customer_id: customer_id,
							entity: entity,
							title: title,
							category: category,
							category_id: category_id,
							folder: folder
						}
					}
				});

				editDialog.afterClosed().subscribe(result => {
					if (result != undefined && result != '') {
						this.document_details = result;
						//console.log(this.document_details);						
						if (this.document_details['link'] != null) {
							this.data.saveDocument(this.document_details).subscribe(
								(res: Response) => {
									//this.navigate('localFunction', 'addAttachment', 'comment', event.body['name']);
								}
							);
						} else {
							this.prepareFilesList($event);
						}
					} else {
						this.files = [];
						this.fileChooser = false;
					}
				});
			} else {
				//direct upload
				this.prepareFilesList($event, true);
			}
		}
	}

	closeDropField() {
		const element = document.getElementsByClassName("file_container")[0] as HTMLElement;
		element.style.visibility = "hidden";
		element.style.opacity = "0";
		const element2 = document.getElementsByClassName("dropdowninfo")[0] as HTMLElement;
		element2.style.visibility = "";
		this.files = [];
		this.fileChooser = false;
	}

	fileBrowseHandler($event) {
		this.onFileDropped($event)
	}

	deleteFile(index: number) { this.files.splice(index, 1); }

	uploadFiles(index: number) {
		this.upload(this.files[index]);
		setTimeout(() => {
			if (index === this.files.length) {
				this.closeDropField();
				this.files = [];
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index] != undefined) {
						if (this.files[index].progress === 100) {
							clearInterval(progressInterval);
							this.uploadFiles(index + 1);
							//if (this.router.url == '/document-center') this.router.navigate([this.router.url]).then(() => {	window.location.reload(); });
							if (this.router.url == '/document-center') this.navigate('localFunction', "refresh", "document-center", null);
							this.fileChooser = false;
						} else {
							this.files[index].progress = this.progress;
						}
					}
				}, 50);
			}
		}, 50);
	}

	upload(currentFile): void {
		this.progress = 0;
		this.UploadService.upload(currentFile).subscribe(
			event => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {

					/* create thumbnail for PDF */
					if (currentFile != undefined) {
						if (currentFile['type'] == "application/pdf") this.convertPdfToImage(currentFile, event.body['name']);
					}

					this.navigate('localFunction', 'addAttachment', 'comment', event.body['name']);
					if (event.body.name != undefined && !event.body['directUpload']) {
						this.document_details['doc_file'] = event.body['name'];
						this.document_details['doc_code'] = event.body['original_name'];
						this.document_details['doc_size'] = currentFile.size;
						this.data.saveDocument(this.document_details).subscribe(
							(res: Response) => { console.log('Uploaded.'); }
						);
					}
				}
			},
			err => {
				this.progress = 0;
				currentFile = undefined;
			}
		);
		this.selectedFiles = undefined;
	}

	formatBytes(bytes, decimals) {
		if (bytes === 0) { return '0 Bytes'; }
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	/***************************/
	convertPdfToImage(file: File, filename): void {
		const fileReader = new FileReader();

		fileReader.onload = async (event: any) => {
			const typedArray = new Uint8Array(event.target.result);

			// Naloži PDF dokument
			const pdf = await pdfjsLib.getDocument(typedArray).promise;

			// Pridobi prvo stran PDF-ja
			const page = await pdf.getPage(1);
			const viewport = page.getViewport({ scale: 1.5 });  // Lahko prilagodiš povečavo

			// Ustvari platno za risanje
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			canvas.height = viewport.height;
			canvas.width = viewport.width;

			// Renderiraj stran na platno
			const renderContext = {
				canvasContext: context,
				viewport: viewport
			};

			await page.render(renderContext).promise;

			// Pretvori platno v sliko in jo shrani kot JPG
			const imgData = canvas.toDataURL('image/jpeg');

			// Uporabi FileSaver.js za shranjevanje
			const imgBlob = this.dataURLtoBlob(imgData);
			//console.log(imgBlob);
			this.uploadBlob(imgBlob, filename);
			//saveAs(imgBlob, 'page1.jpg');
		};

		fileReader.readAsArrayBuffer(file);
	}

	dataURLtoBlob(dataURL: string) {
		const arr = dataURL.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	uploadBlob(blob: Blob, filename): void {
		// Ustvarimo File iz Blob-a
		const file = new File([blob], filename.replace('.pdf', '').replace('.PDF', '') + '.jpg', { type: 'image/jpeg' });

		// Pošlji na PHP skripto
		this.UploadService.uploadThumbnail(file).subscribe(event => { });
	}

	/* END: uploader add on */
	/***************************************************/

}

