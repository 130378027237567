<div class="inner_wrapper">
    <div class="header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="left">
            <h1>{{'My settings'|translate}}</h1>
        </div>
        <div class="right">
            <fa-icon class="icon_close" [icon]="['fad', 'times']" mat-button mat-dialog-close></fa-icon>
        </div>
    </div> 

    <div class="body">        
        <div class="tabs tabsHeader">
            <div class="tab" [ngClass]="{'active':selectedTab == 'aboutme'}" (click)="switchTab('aboutme')">
                <span>{{'About Me'|translate}}</span>
            </div>
            <div class="tab" [ngClass]="{'active':selectedTab == 'preferences'}" (click)="switchTab('preferences')">
                <span>{{'Preferences'|translate}}</span>
            </div>
            <div class="tab" [ngClass]="{'active':selectedTab == 'notifications'}" (click)="switchTab('notifications')">
                <span>{{'Notifications'|translate}}</span>
            </div>            
            <!--
            <div class="tab" [ngClass]="{'active':selectedTab == 'etalk'}" (click)="switchTab('etalk')">
                <span>{{'eTalk'|translate}}</span>
            </div>
            -->
            <!--
            <div class="tab" [ngClass]="{'active':selectedTab == 'google'}" (click)="switchTab('google')">
                <span>{{'Google services'|translate}}</span>
            </div>            
            -->
        </div> 

        <div id="aboutme" *ngIf="selectedTab=='aboutme'" class="tab-container">
            <div class="block">
              
                Za aktivacijo funkcije se obrnite na skrbnika sistema.

            </div>
        </div>


        <div id="preferences" *ngIf="selectedTab=='preferences'" class="tab-container">
            <div class="block">
                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Language'|translate}}</mat-label>
                        <mat-select name="lang" id="lang" [(ngModel)]="memberSettingsGlobal['lang']">
                            <mat-option *ngFor="let language of translate.getLangs()" [value]="language" (click)="setMemberSettings('lang', language)">{{language|translate}}</mat-option>                      
                        </mat-select>                
                    </mat-form-field>
                </div>

                <div class="inline w100 divider"></div>

                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Theme'|translate}}</mat-label>
                        <mat-select name="theme" id="theme" [(ngModel)]="memberSettingsGlobal['theme']">
                            <mat-option value="lunaBasic" (click)="setMemberSettings('theme', 'lunaBasic')">{{'Basic'|translate}}</mat-option>
                        </mat-select>                
                    </mat-form-field>
                </div>
                
                <div class="inline w100 divider"></div>

                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Activity text editor'|translate}}</mat-label>
                        <mat-select name="category" [(ngModel)]="memberSettingsGlobal['editor']">
                            <mat-option value="noeditor" (click)="setMemberSettings('editor', 'noeditor')">{{'Without richtext editor'|translate}}</mat-option>
                            <mat-option value="light" (click)="setMemberSettings('editor', 'light')">{{'Light richtext editor'|translate}}</mat-option>
                            <mat-option value="extended" (click)="setMemberSettings('editor', 'extended')">{{'Extended richtext editor'|translate}}</mat-option>
                        </mat-select>                
                    </mat-form-field>
                </div>

                <div class="inline w100 divider"></div>

                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Display dates'|translate}}</mat-label>
                        <mat-select name="category" [(ngModel)]="memberSettingsGlobal['dates']">
                            <mat-option value="normalAndDay" (click)="setMemberSettings('dates', 'normalAndDay')">{{'Regular dates + week of the day'|translate}}</mat-option>
                            <mat-option value="normal" (click)="setMemberSettings('dates', 'normal')">{{'Regular dates'|translate}}</mat-option>
                            <mat-option value="datesto" (click)="setMemberSettings('dates', 'datesto')">{{'Distance to dates'|translate}}</mat-option>
                            <mat-option value="datestoW" (click)="setMemberSettings('dates', 'datestoW')">{{'Distance to dates + week of the day'|translate}}</mat-option>                    
                        </mat-select>                        
                    </mat-form-field>
                </div>

                <div class="inline w100 divider"></div>

                <div class="inline w100">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Use AI Agent'|translate}}</mat-label>
                        <mat-select name="category" [(ngModel)]="memberSettingsGlobal['aiAgent']">
                            <mat-option value="" (click)="setMemberSettings('aiAgent', null)">{{'Disable AI agent (Beta)'|translate}}</mat-option>
                            <mat-option value="textAgent" (click)="setMemberSettings('aiAgent', 'textAgent')">{{'Text AI agent (Beta)'|translate}}</mat-option>
                            <mat-option value="voiceAgent" (click)="setMemberSettings('aiAgent', 'voiceAgent')">{{'Voice AI agent (Not Available at the Moment)'|translate}}</mat-option>
                            <mat-option value="voiceAgentLog" (click)="setMemberSettings('aiAgent', 'voiceAgentLog')">{{'Voice AI agent with Log (Not Available at the Moment)'|translate}}</mat-option>
                        </mat-select>                        
                    </mat-form-field>
                </div>

                <div class="inline w100 divider"></div>

                <div class="inline w100">
                    <button class="left" (click)="reset()">{{'Reset user defined settings'|translate}}</button>
                </div>        
            </div>
        </div>

        <div id="notifications" *ngIf="selectedTab=='notifications'" class="tab-container">
            <div class="block">
                <label>{{'Email Notifications'|translate}}</label>
                <div class="inline w100 divider"></div>
                <div class="inline w100" style="margin-top:12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'Activity changes'|translate}}</mat-label>
                        <mat-select name="category" [(ngModel)]="memberSettingsGlobal['activityEmail']">
                            <mat-option value="" (click)="setMemberSettings('activityEmail', null)">{{'No notifications'|translate}}</mat-option>                            
                            <mat-option value="instant" (click)="setMemberSettings('activityEmail', 'instant')">{{'Instant when activity change appear'|translate}}</mat-option>                            
                        </mat-select>                        
                    </mat-form-field>
                </div>

                <div class="inline w100" style="margin-top:12px;">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'New ERP document creation'|translate}}</mat-label>
                        <mat-select name="category" [(ngModel)]="memberSettingsGlobal['erpEmail']">
                            <mat-option value="" (click)="setMemberSettings('erpEmail', null)">{{'No notifications'|translate}}</mat-option>                            
                            <mat-option value="instant" (click)="setMemberSettings('erpEmail', 'instant')">{{"Instant to recipients's email"|translate}}</mat-option>                            
                        </mat-select>                        
                    </mat-form-field>
                </div>

                <div class="inline w100 divider"></div>
                <div class="inline w100">
                    <button class="left" (click)="reset()">{{'Reset user defined settings'|translate}}</button>
                </div> 
            </div>
        </div>

    <div class="modalActions">
        <button class="left" mat-button mat-dialog-close>{{'Close'|translate}}</button>
    </div>
</div>
            