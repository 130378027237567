/****************************************************** 
Colabrity.AddOn.Component: V1.0

Author: A. Motore
Date: 2024-09-17
To-Do: Theme implementation
Last activity: Cleaned code

Comment: Need to implement theme service/sollution
*******************************************************/

/* Angular */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

/* Modal */
import { ModalComponent } from '../../modal/modal.component';

/* Services */
import { sha512 } from 'js-sha512';
import { MembersettingsService } from '../../services/membersettings.service';
import { DataService } from '../../services/data.service';
import { GlobalVariables } from '../../services/data.globals';

/* Translation */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})

export class UserSettingsComponent implements OnInit { 
  
	public memberSettingsGlobal = {};
	public memberSettingsLocal = {};
	public theme = "";
	public selectedTab = "aboutme";
	public user = {};
	public changePassword = false;

	constructor(
		private data: DataService,
		private dialog: MatDialog,
		public MembersettingsService: MembersettingsService,
		public translate: TranslateService,
		private cdr: ChangeDetectorRef		
	) { 
		translate.addLangs(['en', 'si']);
	}
  
	/*
 	loadStyle(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0];
	
		let themeLink = this.document.getElementById(
		  'client-theme'
		) as HTMLLinkElement;

		if (themeLink) {
		  themeLink.href = styleName;
		} else {
		  const style = this.document.createElement('link');
		  style.id = 'client-theme';
		  style.rel = 'stylesheet';
		  style.href = `${styleName}`;	
		  head.appendChild(style);
		}
	}
	*/
  	
	/**************************************/
	switchTab(value) {
		this.selectedTab = value;
	}   

	/**************************************/
	setMemberSettings(parameter, value) {
		this.MembersettingsService.setMemberSettings(parameter, value);		
	}

	reset() {	
		let content = this.translate.instant("Do you want parmenently delete all your user settings?<br /><b>You will also be logged out!</b>");
		let modalDialog = this.dialog.open(ModalComponent, {
			data: { content: content, yes: this.translate.instant("Delete") , no: null, cancel: this.translate.instant("Cancel") }
		});

		modalDialog.afterClosed().subscribe(result => {      
			if (result['yes']!=undefined) {
				this.data.resetMemberSettings(null).subscribe( (res: Response) => {} );
				localStorage.clear();
			}
		});  
	}

	/**************************************/

	saveMe() {    
		if (this.user['email'] == undefined || this.user['username'] == undefined || this.user['email'] == "" || this.user['username'] == "") {
			var content = 'You must to fill up neccessary fields!';
			var modalDialog = this.dialog.open(ModalComponent, {
			data: { content: content, yes: null , no: null, cancel: 'Close', close_parent: false }
			});
			return false;
		}
		
		var parameters = { data: this.user };
		
		if (parameters['data']['password']!=null) {
			parameters['data']['password'] = sha512(parameters['data']['password']);    
		}   

		//console.log(parameters);           		
		this.data.saveUser(parameters).subscribe(
			(res: Response) => {   
			let entityCallBack = <any>res['callback']['entity'];
		
			for (const key in this.user) {      
				if (key != 'password' && key != 'entity' && key != 'apps' && key != 'user_group' && key != 'username' && key != 'email' && key != 'admin_right' &&  key != 'admin_right') {				
					this.data.setMemberData({'id': entityCallBack, 'parameter': key, 'value': this.user[key]}).subscribe(
						(res: Response) => {   
							console.log(res);					
						}
					);
				}
			}

		});
		
		return true;
	}

	/**************************************/
	ngOnInit(): void {    		
		/* Member.Service */
		this.MembersettingsService.refreshMemberSettings();
		this.MembersettingsService.memberSettingsGlobal.subscribe(message => {						
			
			//console.log(message);
			/* angular material display fix - not good solution!!! */
			setTimeout(() => { this.memberSettingsGlobal = message;	}, 100);
			//this.memberSettingsGlobal = message;

			/* Set Language */
			if (this.memberSettingsGlobal['lang'] != undefined) this.translate.use(this.memberSettingsGlobal['lang']);
		});
		
		
		this.data.getMembers({ items : 1, page : 0, order : 't1.entity', orderby : 'asc', search : null, field: 't1.entity', value : GlobalVariables.id }).subscribe(
			(res: Response) => {       
			  this.user = (<any>res).rows[0];		
			  
			  this.data.getMemberData({ user_id : this.user['entity']}).subscribe(
				(res: Response) => {
				  let user_data = (<any>res).rows;
				  for(var i=0; i<user_data.length; i++) {
					this.user[user_data[i]['fieldname']] = user_data[i]['data'];
				  }              
				}
			  );			  
			}
		);

	}
	

}
